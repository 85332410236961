import Head from 'next/head';
import { useRouter } from 'next/router';
import { twitchHandle, twitterHandle } from '@/helper/constants';

const url = "https://mursai.stream"

const defaultMeta = {
    title: twitchHandle,
    siteName: twitchHandle,
    description: 'Welcome to the hectic world of yours truly! Prepare to get your ears destroyed, and your sides hurting. These streams are the definition of **chaotic**',
    url: url,
    type: 'website',
    robots: 'follow, index',
    image: url + '/images/og.png',
    themeColor: "#b84ca0"
};

type SeoProps = {
    date?: string;
    templateTitle?: string;
} & Partial<typeof defaultMeta>;

export default function Seo(props: SeoProps) {
    const router = useRouter();
    const meta = {
        ...defaultMeta,
        ...props,
    };
    meta['title'] = props.templateTitle
        ? `${props.templateTitle} | ${meta.siteName}`
        : meta.title;


    return (
        <Head>
            <title>{meta.title}</title>
            <meta name='robots' content={meta.robots} />
            <meta name="viewport" content="width=device-width,initial-scale=1" />

            <meta content={meta.description} name='description' />
            <meta property='og:url' content={`${meta.url}${router.asPath}`} />
            <link rel='canonical' href={`${meta.url}${router.asPath}`} />
            {/* Open Graph */}
            <meta property='og:type' content={meta.type} />
            <meta property='og:site_name' content={meta.siteName} />
            <meta property='og:description' content={meta.description} />
            <meta property='og:title' content={meta.title} />
            <meta name="keywords"
                content="ArcticMursai, arctic mursai, mursai,brawlhalla"
            />
            <meta name='image' property='og:image' content={meta.image} />
            {/* Twitter */}
            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:site' content={`@${twitterHandle}`} />
            <meta name='twitter:title' content={meta.title} />
            <meta name='twitter:description' content={meta.description} />
            <meta name='twitter:image' content={meta.image} />
            {meta.date && (
                <>
                    <meta property='article:published_time' content={meta.date} />
                    <meta
                        name='publish_date'
                        property='og:publish_date'
                        content={meta.date}
                    />
                    <meta
                        name='author'
                        property='article:author'
                        content='Arctic Mursai'
                    />
                </>
            )}

            {/* Favicons */}
            {favicons.map((linkProps) => (
                <link key={linkProps.href} {...linkProps} />
            ))}
            <meta name='msapplication-TileColor' content={meta.themeColor} />
            <meta name='theme-color' content={meta.themeColor} />
        </Head>
    );
}

type Favicons = {
    rel: string;
    href: string;
    sizes?: string;
    type?: string;
};

const favicons: Array<Favicons> = [
    {
        rel: "icon",
        type: "image/x-icon",
        href: "/favicon.ico"
    },
    {
        rel: "icon",
        type: "image/png",
        href: "/favicon.png"
    }
]
