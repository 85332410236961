import { motion } from "framer-motion";
import { twitchHandle } from "@/helper/constants";
import { TwitchEmbed } from 'react-twitch-embed';
import { useEffect, useState } from "react";
import Seo from "@/components/SEO";


const Index = () => {
    const [dark, setDark] = useState(false)
    useEffect(() => {
    const isDark = localStorage.getItem("theme") === "dark"
    setDark(isDark)
    }, [])
    
    return (
        <>
        <Seo templateTitle="Home" />
        <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ ease: "easeOut", duration: 0.15 }}
            className="w-full mb-32 grid justify-center"
        >

            <h1 className="font-bold text-4xl md:text-5xl mb-4 text-center">{twitchHandle}</h1>
            <p className="text-gray-800 mx-6 dark:text-gray-300 leading-6 tracking-wide mb-12">
            Welcome to the hectic world of yours truly! Prepare to get your ears destroyed, and your sides hurting. These streams are the definition of <b>chaotic</b>
</p>
<TwitchEmbed width={"100%"} channel={twitchHandle} autoplay
muted={false} withChat darkMode={dark}/>
        </motion.div>
        </>
    );
};

// export async function getStaticProps() {
//     return {
//         props: {  },
//     };
// }

export default Index;
